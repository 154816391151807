import React from "react";

const Logo = ({ variant }) => {
  if (variant === "cropped") {
    return (
      <svg
        width="72"
        height="58"
        viewBox="0 0 72 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.971 14.0388C48.2694 20.0815 53.6407 25.9003 60.5039 25.6019C69.3813 25.2289 71.0971 18.664 71.0971 18.0672C71.0971 17.918 71.0225 17.8434 70.8733 17.8434L70.2765 17.6196C70.0527 17.545 69.8289 17.6942 69.6797 17.9926C68.7845 19.7085 67.3671 21.0513 65.5021 21.5735C61.3991 22.7671 56.9231 19.7831 55.5057 14.7102C54.2375 10.3834 55.5803 5.83279 58.6389 3.96778C60.8769 2.69957 63.7863 3.44558 65.1291 5.68359C66.4719 7.9216 65.8005 10.831 63.5625 12.1738C61.9959 13.1436 59.5341 13.069 57.8929 11.5024C57.3707 11.0548 57.3707 11.95 57.3707 12.0992C57.3707 12.323 57.4453 13.1436 58.2659 14.0388C60.5039 16.426 64.1593 16.9482 67.0687 15.2324C70.4257 13.2182 71.5447 8.8168 69.4559 5.38518C69.0083 4.63918 66.3227 0.685363 59.5341 0.909164C54.7597 1.13296 47.598 5.38519 47.971 14.0388"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.3764 20.3054L46.8542 19.9324C46.6304 19.7832 46.4066 19.8578 46.1828 20.1562C44.9146 21.6482 43.1987 22.5434 41.2591 22.618C37.0069 22.7672 33.3515 18.6642 33.1277 13.4421C32.9039 8.1455 36.1863 3.74408 40.4385 3.59488C42.5273 3.52028 44.4669 4.49008 45.959 6.05669C46.0336 6.13129 46.1082 6.05669 46.2574 5.98209L46.705 5.60909C46.7796 5.53449 46.8542 5.45989 46.8542 5.31069C46.8542 4.56468 43.7955 0.685463 37.6037 0.983865C30.7405 1.28227 25.5185 7.0265 25.8169 13.8897C26.1153 20.6784 31.8595 25.975 38.7227 25.6766C45.4368 25.3782 47.5256 20.8276 47.5256 20.5292C47.5256 20.4546 47.451 20.38 47.3764 20.3054"
          fill="#F9B000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2778 25.1544C28.2778 25.1544 27.8302 24.6322 27.681 24.5576C26.1144 22.9163 22.832 19.1117 22.832 12.6961C22.832 5.83286 18.729 1.20764 11.8658 1.20764C4.62951 1.20764 2.5407 5.75826 2.5407 6.05667C2.5407 6.20587 2.61531 6.28047 2.68991 6.35507L3.21211 6.72807C3.28671 6.80267 3.36131 6.80267 3.51051 6.80267C4.85332 5.08686 6.79293 4.04245 8.88174 3.96785C12.761 3.89325 15.372 6.65347 15.8196 11.2041C15.745 11.2041 15.745 11.2041 15.6704 11.1295C15.372 10.9057 13.8054 9.11528 9.55314 9.11528C4.70411 9.11528 0.824895 12.9945 0.899496 17.5451C0.974096 22.1703 5.00252 25.7512 10.0007 25.7512C14.7006 25.7512 16.7148 22.6925 16.7148 22.6179C16.7148 22.4687 16.7148 22.3941 16.6402 22.3195L16.1926 21.8719C15.9688 21.7227 15.745 21.7973 15.5212 21.9465C10.8959 25.4528 7.09133 22.0211 7.09133 18.1419C7.09133 14.2627 10.2991 12.4723 11.9404 12.4723C15.0736 12.4723 15.8196 14.3373 16.118 15.9039C17.7592 25.229 25.3684 26.348 27.9048 25.602C28.5016 25.3782 28.2778 25.1544 28.2778 25.1544Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8633 39.7014C16.8633 36.2698 20.2203 33.6588 24.2487 33.6588C28.2025 33.6588 31.0374 35.8968 31.0374 39.2538C31.0374 42.6109 27.6803 45.2219 23.5773 45.2219C19.7727 45.2219 16.8633 42.9839 16.8633 39.7014V39.7014ZM27.9787 39.8506C27.9787 36.6428 26.1883 34.8524 23.7265 34.8524C21.4885 34.8524 19.8473 36.2698 19.8473 39.03C19.8473 42.0887 21.5631 43.8791 24.0995 43.8791C27.8295 43.8045 27.9787 40.522 27.9787 39.8506V39.8506Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M39.096 35.2254C41.2594 35.2254 42.08 35.0016 42.7514 36.792C42.826 37.0158 42.826 37.0158 42.9752 37.0158H43.3482C43.4974 37.0158 43.4974 37.0158 43.4974 36.8666V34.1064C43.4974 33.9572 43.4974 33.9572 43.3482 33.9572H31.9344C31.7852 33.9572 31.7852 34.0318 31.7852 34.1064V36.7174C31.7852 36.9412 31.7852 37.0158 31.9344 37.0158H32.382C32.4566 37.0158 32.5312 36.9412 32.5312 36.792C32.9788 35.4492 33.7994 35.2254 35.0676 35.2254H36.2612V44.998H39.1706V35.2254H39.096Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M55.805 42.3125H55.432C55.3574 42.3125 55.2827 42.4617 55.2827 42.6109C54.8351 43.8791 54.1637 43.7299 52.6717 43.7299H49.7623V39.8507H52.8955C53.4177 39.8507 54.3129 40.2237 54.4621 41.1935C54.4621 41.3427 54.5367 41.4173 54.6859 41.4173H55.0589C55.1336 41.4173 55.2081 41.3427 55.2081 41.2681V37.6127C55.2081 37.5381 55.1335 37.4635 55.1335 37.4635H54.6859C54.6113 37.4635 54.5367 37.6127 54.5367 37.6873C54.3875 38.5825 53.7161 38.6571 52.8209 38.6571H49.7623V35.2255H52.8955C53.7161 35.2255 54.8351 35.0763 55.1335 36.0461C55.2081 36.1953 55.2081 36.1953 55.2827 36.1953H55.6557C55.7303 36.1953 55.805 36.1207 55.805 36.1207V33.9573C55.805 33.8827 55.7303 33.8827 55.6557 33.8827H45.1371C45.0625 33.8827 45.0625 33.9573 45.0625 34.0319V34.4049C45.0625 34.5541 45.0625 34.5541 45.1371 34.5541H45.5101C46.6291 34.6287 46.7037 34.8525 46.7037 37.6127V44.9982H55.7304C55.8796 44.9982 55.8796 44.9236 55.8796 44.849V42.4617C55.9542 42.3125 55.9542 42.3125 55.805 42.3125"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.1132 41.8648H67.8894C67.7402 41.8648 67.7402 41.9394 67.591 42.1632C67.0688 43.5806 66.2482 43.7298 64.0847 43.7298H61.9959V33.9572H57.5199C57.4453 33.9572 57.4453 34.0318 57.4453 34.1064V34.4794C57.4453 34.6286 57.4453 34.6286 57.5199 34.6286H57.8929C59.0119 34.7032 59.0865 34.927 59.0865 37.6872V45.0726H68.0386C68.2624 45.0726 68.337 44.998 68.337 44.7742V42.1632C68.337 41.9394 68.2624 41.8648 68.1132 41.8648Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5968 33.9572C10.5222 33.9572 10.5222 34.0318 10.5222 34.1064V34.4794C10.5222 34.6286 10.5222 34.6286 10.5968 34.6286H10.9698C12.0888 34.7032 12.1634 34.927 12.1634 37.6872V38.5824H6.04613V34.0318H3.13672V45.1472H6.04613V39.9998H12.1634V45.1472H15.0728V33.9572H10.5968Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7592 30.6748H25.4448L27.2352 33.2112H26.4892L23.8782 31.6446L21.6402 33.2112H20.9688L22.7592 30.6748Z"
          fill="#171930"
        />
        <path
          d="M28.5134 52.1167C28.2874 52.2754 28.2329 52.5872 28.3916 52.8132C28.5503 53.0392 28.8622 53.0937 29.0882 52.935L28.5134 52.1167ZM37.7774 55.1822L37.3159 55.3746L37.5149 55.8517L37.985 55.637L37.7774 55.1822ZM42.7575 56.9314C42.8853 57.1762 43.1873 57.2711 43.4321 57.1433C43.6769 57.0156 43.7718 56.7135 43.6441 56.4687L42.7575 56.9314ZM29.0882 52.935C30.2204 52.1397 31.8222 51.6207 33.3528 51.8579C34.8473 52.0896 36.3477 53.0528 37.3159 55.3746L38.2389 54.9897C37.15 52.3786 35.3776 51.1599 33.5059 50.8697C31.6703 50.5852 29.8123 51.2044 28.5134 52.1167L29.0882 52.935ZM37.7774 55.1822C37.985 55.637 37.9849 55.6371 37.9847 55.6372C37.9847 55.6372 37.9845 55.6373 37.9844 55.6373C37.9843 55.6374 37.9842 55.6374 37.9841 55.6374C37.9841 55.6375 37.9842 55.6374 37.9846 55.6373C37.9853 55.6369 37.9869 55.6362 37.9893 55.6351C37.9942 55.633 38.0025 55.6294 38.014 55.6246C38.037 55.6149 38.0728 55.6002 38.1201 55.5821C38.2147 55.5459 38.3543 55.4964 38.5278 55.4459C38.8771 55.3443 39.3521 55.2422 39.8683 55.2315C40.8827 55.2105 42.0282 55.5338 42.7575 56.9314L43.6441 56.4687C42.6903 54.641 41.124 54.2053 39.8476 54.2317C39.2183 54.2448 38.6531 54.368 38.2484 54.4857C38.045 54.5449 37.8792 54.6036 37.7627 54.6481C37.7044 54.6704 37.6582 54.6893 37.6256 54.703C37.6093 54.7099 37.5964 54.7155 37.587 54.7196C37.5824 54.7217 37.5786 54.7234 37.5757 54.7247C37.5742 54.7253 37.573 54.7259 37.572 54.7263C37.5715 54.7265 37.5711 54.7267 37.5707 54.7269C37.5705 54.727 37.5703 54.7271 37.5702 54.7271C37.57 54.7272 37.5698 54.7273 37.7774 55.1822Z"
          fill="#F9B000"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="108"
        height="72"
        viewBox="0 0 108 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.971 23.0387C66.2694 29.0813 71.6407 34.9002 78.5039 34.6018C87.3813 34.2288 89.0971 27.6639 89.0971 27.0671C89.0971 26.9179 89.0225 26.8433 88.8733 26.8433L88.2765 26.6195C88.0527 26.5449 87.8289 26.6941 87.6797 26.9925C86.7845 28.7083 85.3671 30.0511 83.5021 30.5733C79.3991 31.7669 74.9231 28.7829 73.5057 23.7101C72.2375 19.3833 73.5803 14.8327 76.6389 12.9677C78.8769 11.6995 81.7863 12.4455 83.1291 14.6835C84.4719 16.9215 83.8005 19.8309 81.5625 21.1737C79.9959 22.1435 77.5341 22.0689 75.8929 20.5023C75.3707 20.0547 75.3707 20.9499 75.3707 21.0991C75.3707 21.3229 75.4453 22.1435 76.2659 23.0387C78.5039 25.4259 82.1593 25.9481 85.0687 24.2323C88.4257 22.2181 89.5447 17.8167 87.4559 14.3851C87.0083 13.6391 84.3227 9.68524 77.5341 9.90904C72.7597 10.1328 65.598 14.3851 65.971 23.0387"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M65.3764 29.3054L64.8542 28.9324C64.6304 28.7832 64.4066 28.8578 64.1828 29.1562C62.9146 30.6482 61.1987 31.5434 59.2591 31.618C55.0069 31.7672 51.3515 27.6642 51.1277 22.4421C50.9039 17.1455 54.1863 12.7441 58.4385 12.5949C60.5273 12.5203 62.4669 13.4901 63.959 15.0567C64.0336 15.1313 64.1082 15.0567 64.2574 14.9821L64.705 14.6091C64.7796 14.5345 64.8542 14.4599 64.8542 14.3107C64.8542 13.5647 61.7955 9.68546 55.6037 9.98386C48.7405 10.2823 43.5185 16.0265 43.8169 22.8897C44.1153 29.6784 49.8595 34.975 56.7227 34.6766C63.4368 34.3782 65.5256 29.8276 65.5256 29.5292C65.5256 29.4546 65.451 29.38 65.3764 29.3054"
          fill="#F9B000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.2778 34.1542C46.2778 34.1542 45.8302 33.632 45.681 33.5574C44.1144 31.9162 40.832 28.1116 40.832 21.696C40.832 14.8327 36.729 10.2075 29.8658 10.2075C22.6295 10.2075 20.5407 14.7581 20.5407 15.0565C20.5407 15.2057 20.6153 15.2803 20.6899 15.3549L21.2121 15.7279C21.2867 15.8025 21.3613 15.8025 21.5105 15.8025C22.8533 14.0867 24.7929 13.0423 26.8817 12.9677C30.761 12.8931 33.372 15.6533 33.8196 20.204C33.745 20.204 33.745 20.204 33.6704 20.1294C33.372 19.9056 31.8054 18.1152 27.5531 18.1152C22.7041 18.1152 18.8249 21.9944 18.8995 26.545C18.9741 31.1702 23.0025 34.751 28.0007 34.751C32.7006 34.751 34.7148 31.6924 34.7148 31.6178C34.7148 31.4686 34.7148 31.394 34.6402 31.3194L34.1926 30.8718C33.9688 30.7226 33.745 30.7972 33.5212 30.9464C28.8959 34.4526 25.0913 31.021 25.0913 27.1418C25.0913 23.2626 28.2991 21.4722 29.9404 21.4722C33.0736 21.4722 33.8196 23.3372 34.118 24.9038C35.7592 34.2288 43.3684 35.3478 45.9048 34.6018C46.5016 34.378 46.2778 34.1542 46.2778 34.1542Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.8633 48.7013C34.8633 45.2697 38.2203 42.6587 42.2487 42.6587C46.2025 42.6587 49.0374 44.8967 49.0374 48.2537C49.0374 51.6107 45.6803 54.2217 41.5773 54.2217C37.7727 54.2217 34.8633 51.9837 34.8633 48.7013V48.7013ZM45.9787 48.8505C45.9787 45.6427 44.1883 43.8523 41.7265 43.8523C39.4885 43.8523 37.8473 45.2697 37.8473 48.0299C37.8473 51.0885 39.5631 52.8789 42.0995 52.8789C45.8295 52.8043 45.9787 49.5219 45.9787 48.8505V48.8505Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.096 44.2252C59.2594 44.2252 60.08 44.0014 60.7514 45.7918C60.826 46.0156 60.826 46.0156 60.9752 46.0156H61.3482C61.4974 46.0156 61.4974 46.0156 61.4974 45.8664V43.1062C61.4974 42.957 61.4974 42.957 61.3482 42.957H49.9344C49.7852 42.957 49.7852 43.0316 49.7852 43.1062V45.7172C49.7852 45.9411 49.7852 46.0156 49.9344 46.0156H50.382C50.4566 46.0156 50.5312 45.941 50.5312 45.7918C50.9788 44.449 51.7994 44.2252 53.0676 44.2252H54.2612V53.9979H57.1706V44.2252H57.096Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M73.805 51.3124H73.432C73.3574 51.3124 73.2827 51.4616 73.2827 51.6108C72.8351 52.879 72.1637 52.7298 70.6717 52.7298H67.7623V48.8506H70.8955C71.4177 48.8506 72.3129 49.2236 72.4621 50.1934C72.4621 50.3426 72.5367 50.4172 72.6859 50.4172H73.0589C73.1336 50.4172 73.2081 50.3426 73.2081 50.268V46.6126C73.2081 46.538 73.1335 46.4634 73.1335 46.4634H72.6859C72.6113 46.4634 72.5367 46.6126 72.5367 46.6872C72.3875 47.5824 71.7161 47.657 70.8209 47.657H67.7623V44.2254H70.8955C71.7161 44.2254 72.8351 44.0762 73.1335 45.046C73.2081 45.1952 73.2081 45.1952 73.2827 45.1952H73.6557C73.7303 45.1952 73.805 45.1206 73.805 45.1206V42.9572C73.805 42.8826 73.7303 42.8826 73.6557 42.8826H63.1371C63.0625 42.8826 63.0625 42.9572 63.0625 43.0318V43.4048C63.0625 43.554 63.0625 43.554 63.1371 43.554H63.5101C64.6291 43.6286 64.7037 43.8524 64.7037 46.6126V53.998H73.7304C73.8796 53.998 73.8796 53.9234 73.8796 53.8488V51.4616C73.9542 51.3124 73.9542 51.3124 73.805 51.3124"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M86.1132 50.8647H85.8894C85.7402 50.8647 85.7402 50.9393 85.591 51.1631C85.0688 52.5805 84.2482 52.7297 82.0847 52.7297H79.9959V42.957H75.5199C75.4453 42.957 75.4453 43.0316 75.4453 43.1062V43.4792C75.4453 43.6284 75.4453 43.6284 75.5199 43.6284H75.8929C77.0119 43.703 77.0865 43.9268 77.0865 46.687V54.0725H86.0386C86.2624 54.0725 86.337 53.9979 86.337 53.7741V51.1631C86.337 50.9393 86.2624 50.8647 86.1132 50.8647Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.5968 42.957C28.5222 42.957 28.5222 43.0316 28.5222 43.1062V43.4792C28.5222 43.6284 28.5222 43.6284 28.5968 43.6284H28.9698C30.0888 43.703 30.1634 43.9268 30.1634 46.687V47.5823H24.0461V43.0316H21.1367V54.1471H24.0461V48.9997H30.1634V54.1471H33.0728V42.957H28.5968Z"
          fill="#171930"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.7592 39.6748H43.4448L45.2352 42.2112H44.4892L41.8782 40.6446L39.6402 42.2112H38.9688L40.7592 39.6748Z"
          fill="#171930"
        />
        <path
          d="M46.5134 61.1167C46.2874 61.2754 46.2329 61.5872 46.3916 61.8132C46.5503 62.0392 46.8622 62.0937 47.0882 61.935L46.5134 61.1167ZM55.7774 64.1822L55.3159 64.3746L55.5149 64.8517L55.985 64.637L55.7774 64.1822ZM60.7575 65.9314C60.8853 66.1762 61.1873 66.2711 61.4321 66.1433C61.6769 66.0156 61.7718 65.7135 61.6441 65.4687L60.7575 65.9314ZM47.0882 61.935C48.2204 61.1397 49.8222 60.6207 51.3528 60.8579C52.8473 61.0896 54.3477 62.0528 55.3159 64.3746L56.2389 63.9897C55.15 61.3786 53.3776 60.1599 51.5059 59.8697C49.6703 59.5852 47.8123 60.2044 46.5134 61.1167L47.0882 61.935ZM55.7774 64.1822C55.985 64.637 55.9849 64.6371 55.9847 64.6372C55.9847 64.6372 55.9845 64.6373 55.9844 64.6373C55.9843 64.6374 55.9842 64.6374 55.9841 64.6374C55.9841 64.6375 55.9842 64.6374 55.9846 64.6373C55.9853 64.6369 55.9869 64.6362 55.9893 64.6351C55.9942 64.633 56.0025 64.6294 56.014 64.6246C56.037 64.6149 56.0728 64.6002 56.1201 64.5821C56.2147 64.5459 56.3543 64.4964 56.5278 64.4459C56.8771 64.3443 57.3521 64.2422 57.8683 64.2315C58.8827 64.2105 60.0282 64.5338 60.7575 65.9314L61.6441 65.4687C60.6903 63.641 59.124 63.2053 57.8476 63.2317C57.2183 63.2448 56.6531 63.368 56.2484 63.4857C56.045 63.5449 55.8792 63.6036 55.7627 63.6481C55.7044 63.6704 55.6582 63.6893 55.6256 63.703C55.6093 63.7099 55.5964 63.7155 55.587 63.7196C55.5824 63.7217 55.5786 63.7234 55.5757 63.7247C55.5742 63.7253 55.573 63.7259 55.572 63.7263C55.5715 63.7265 55.5711 63.7267 55.5707 63.7269C55.5705 63.727 55.5703 63.7271 55.5702 63.7271C55.57 63.7272 55.5698 63.7273 55.7774 64.1822Z"
          fill="#F9B000"
        />
      </svg>
    );
  }
};

export default Logo;
