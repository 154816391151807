import React, { useState, useEffect, useRef } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useIntl } from "react-intl";

import Adult from "./adult";

const Account = () => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    query {
      allContentfulEspaceClient {
        nodes {
          slug
          node_locale
        }
      }
    }
  `);

  const account = data.allContentfulEspaceClient.nodes.find(
    (page) => page.node_locale === intl.locale
  );

  const [dropdown, setDropdown] = useState(null);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  function toggleDropdown() {
    setDropdown(!dropdown ? true : null);
  }

  function closeDropdown(event) {
    if (
      !buttonRef.current.contains(event.target) &&
      !dropdownRef.current.contains(event.target)
    ) {
      setDropdown(null);
    }
  }

  useEffect(() => {
    document.addEventListener("click", closeDropdown, true);
    return () => {
      document.removeEventListener("click", closeDropdown, true);
    };
  }, []);

  return (
    <div className="account">
      <button
        className="account-button"
        onClick={toggleDropdown}
        ref={buttonRef}
        aria-label={intl.formatMessage({ id: "account.title" })}
      >
        <Adult />
      </button>
      <div className="account-dropdown" hidden={!dropdown} ref={dropdownRef}>
        <a
          className="button w-100 mb-4"
          href={`https://www.secure-direct-hotel-booking.com/customer/lQM8UpTFUCbP5--hRVW3Xu3ZuB8ofe0xLTqy5g9-QSs@/${intl.locale.toUpperCase()}/login`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: "account.sign-in" })}
        </a>
        <a
          href={`https://www.secure-direct-hotel-booking.com/customer/lQM8UpTFUCbP5--hRVW3Xu3ZuB8ofe0xLTqy5g9-QSs@/${intl.locale.toUpperCase()}/login`}
          target="_blank"
          rel="noreferrer"
        >
          {intl.formatMessage({ id: "account.sign-up" })}
        </a>
        <Link className="link mt-8" to={`/${intl.locale}/${account.slug}/`}>
          {intl.formatMessage({ id: "account.discover" })}
        </Link>
      </div>
    </div>
  );
};

export default Account;
