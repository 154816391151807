import React from "react";

function Adult() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        d="M15 7c0 2.566-1.67 4-3 4S9 9.566 9 7s1.67-4 3-4 3 1.434 3 4zm2 0c0 3.314-2.239 6-5 6s-5-2.686-5-6 2.239-6 5-6 5 2.686 5 6zM4 22c0-1.218.74-2.444 2.19-3.41C7.635 17.627 9.686 17 12 17c2.315 0 4.366.628 5.81 1.59C19.26 19.555 20 20.781 20 22h2c0-2.095-1.274-3.87-3.081-5.075C17.104 15.715 14.655 15 12 15c-2.655 0-5.104.716-6.919 1.925C3.274 18.13 2 19.905 2 22h2z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Adult;
